import React, { Component } from "react";
import "./projects.styles.scss";

class ProjectsMac extends Component {
  render() {
    return (
      <div className="ProjectsMac">
        {this.props.leng.map((p) => {
          return <span>{p}</span>;
        })}
      </div>
    );
  }
}

export default ProjectsMac;
