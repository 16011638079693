import React,{Component} from 'react';
import  './fastPage.styles.scss';
import '../button.styles.scss';
import ScrollTo from '../../components/ScrollTo/scrollTo';
import Icons from '../incons/icons';
import TypeWritter from 'typewriter-effect';
// npm install typewriter-effect
class FastPage extends Component {
  render() {
    
    return (
    <div className='fastPage' id='home'>

      <span className='fastPageAnimation'>
        <span className='midTextFastPage'>Hey there</span><br/>
      </span>

      {/* <TypeWritter
        //className='TypeWritter'
        onInit={(type)=>{
          type
          .changeDelay(100)
          .typeString("<span className='TypeWritter'>I Am</span><br/>")
          .pauseFor(800)
          .typeString(`<span style={{fontSize:'200px'}}>Ashak Zahin</span>`)
          .start();
        }}
      /> */}

      <div className='TypeWritter fastPageAnimation'>
        <div>I Am</div>
        <div >Ashak Zahin</div>
      </div>
      <span className='fastPageAnimation' >
          <span className='midTextFastPage'>I create mobile and web apps</span>
      </span>
      <br/>
      <span className='fastPageAnimation'>
          <Icons/>
      </span>  
          <button onClick={this.props.startClick} className='startButton'>
              <div className='btnTextHomePage'>
                <ScrollTo className='btnTextHomePage' name='More Info' to='AboutMe'/>
              </div>
          </button>
    </div>


)}
}

export default FastPage;
