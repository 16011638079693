import React,{Component} from 'react';
import  './scrollTo.styles.scss';

import { Link } from 'react-scroll'



class scrollTo extends Component {




  render() {

    return (
    <div className='scrollTo'>
    
        <Link className='Link'
            onClick={this.props.toggleBar}
            to={this.props.to}
            smooth={true} 
            offset={-5}
            spy={true}
            duration={800}>
                {this.props.name}
        </Link>
    </div>


)}
}

export default scrollTo;
