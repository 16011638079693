import * as firebase from 'firebase';

export const firebaseConfig = {
  apiKey: "AIzaSyCl3YJ0ZFuhg2ZGidLIKWXUAnUhBV9zhDk",
  authDomain: "aboutme-b0750.firebaseapp.com",
  projectId: "aboutme-b0750",
  storageBucket: "aboutme-b0750.appspot.com",
  messagingSenderId: "585720950943",
  appId: "1:585720950943:web:44cc4cdf0c7430545af2aa",
  measurementId: "G-CRQ2KYKK99"
};


//export const firebaseAnalytics = firebase.analytics();