import React, { Component } from "react";
import "./CVpart.styles.scss";
import "../../pages/button.styles.scss";
import File from "../../files/Ashak_Zahin_Hasan.pdf";
import ContactMe from "../../pages/contactMe/contactMe";
import Reveal from "react-reveal/Reveal";
//npm install react-reveal --save
//https://www.react-reveal.com/tutorials/
class CVPart extends Component {
  alert = () => {
    alert(
      "Temporary Disabled! You Can Mail from 'Let's Talk' part. I will send you the CV :) "
    );
  };
  render() {
    return (
      <div className="CvPart" id="CV">
        <br />
        <Reveal effect="H1animation">
          <h1 className="CVH1">My CV</h1>
        </Reveal>
        <br />

        {/* <a onClick={this.alert }> */}
        <a href={File} download>
          <button className="CVButton">Download</button>
        </a>

        {/* <a href="mailto:azahinhasan@gmail.com?subject=Wanna Contact with You" target='_blank'>
            <button className='CVButton}>
                Let's Talk
            </button>
        </a> */}

        <ContactMe />
      </div>
    );
  }
}

export default CVPart;
