import React,{Component} from 'react';
import  './toggleSideBar.styles.scss';
import ScrollTo from '../ScrollTo/scrollTo';
import DrawerToggle from './drawerToggle';
import {Box,Paper} from '@mui/material';


class ToggleSideBar extends Component {

    scrollToTop=()=> {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
  render() {

    return (
    <div>
        {this.props.showToggleSideBar ? 
            <div className='toggleSideBar'>
                <div className="toggleSideBarOptions">
                <div className='toggleOptions' onClick={this.props.toggleBar}><div style={{padding:'8px'}} onClick={()=>this.scrollToTop()}>Home</div></div>
                <div className='toggleOptions'onClick={this.props.toggleBar}><ScrollTo toggleBar={this.props.toggleBar} name='About Me' to='AboutMe'/></div>
                <div className='toggleOptions'onClick={this.props.toggleBar}><ScrollTo toggleBar={this.props.toggleBar} name='Skills' to='skills'/></div>
                <div className='toggleOptions'onClick={this.props.toggleBar}><ScrollTo toggleBar={this.props.toggleBar} name='Qualification' to='qualification'/></div>
                <div className='toggleOptions'onClick={this.props.toggleBar}><ScrollTo toggleBar={this.props.toggleBar} name='Projects' to='projects'/></div>
                <div className='toggleOptions'onClick={this.props.toggleBar}><ScrollTo toggleBar={this.props.toggleBar} name='CV' to='CV'/></div>
                </div>
            </div>
        :null}
        <DrawerToggle clicked={this.props.toggleBar}/>
    </div>
)}
}

export default ToggleSideBar;
