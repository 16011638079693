import React,{useState} from 'react';
import  './icons.styles.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
const Icons =()=> {

    return (
    <div className='icons'>

      <span className='fastPageAnimation'>
        <a  className='iconAngorTag'  href="mailto:azahinhasan@gmail.com?subject=Wanna Contact with You" target='_blank'><MailOutlineIcon style={{fontSize:"34px",padding:"5px"}} className='mail' /></a>
        <a  className='iconAngorTag'  href='tel: +8801515667288' target='_blank'><LocalPhoneIcon style={{fontSize:"34px",padding:"5px"}} className='phone' /></a>
      </span>
      
        <br/>
        
      <span className='fastPageAnimation'>
      <a href='https://www.facebook.com/a.h.zahin/' target='_blank'><FacebookIcon style={{fontSize:"34px",padding:"5px"}} className='facebook'/></a>
      <a href='https://www.instagram.com/potato.coderr/?hl=en' target='_blank'><InstagramIcon  style={{fontSize:"34px",padding:"5px"}} className='instagram'/></a>
      <a href='https://github.com/azahinhasan' target='_blank'><GitHubIcon  style={{fontSize:"34px",padding:"5px"}} className='github'/></a>
      <a href='https://www.linkedin.com/in/ashak-zahin-hasan-099455133/' target='_blank'><LinkedInIcon  style={{fontSize:"34px",padding:"5px"}} className='linkedin'/></a>
        {/* <a href='https://www.facebook.com/a.h.zahin/' target='_blank'><img src={FB}/></a>
        <a href='https://github.com/azahinhasan' target='_blank'><img src={GitHub}/></a>
        <a href='https://www.instagram.com/potato.coderr/' target='_blank'><img src={Insta}/></a>
        <a href='https://www.linkedin.com/in/ashhak-hasan-099455133/' target='_blank'><img src={LinkedIn}/></a> */}
      </span>

    </div>


)
}

export default Icons;
