import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./projects.styles.scss";
import ProjectsMac from "./projectsMachins";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  console.log(other.value);

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({ data, description }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="popUpDeatils">
      <Button className="btnShowPupUp" onClick={handleClickOpen}>
        Details
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{ backgroundColor: "#c1c9d3" }}>
          <BootstrapDialogTitle
            style={{ textDecoration: "none", color: "black", fontSize: "17px" }}
            onClose={handleClose}
          >
            <h3 style={{ textAlign: "center" }}>Details</h3>
            <h2>{data.pName}</h2>
            <ProjectsMac leng={data.leng} for={"deatilsPopUp"} />
            <br />

            <div style={{ textAlign: "center" }}>
              {!data.organizationApp ? (
                <>
                  {" "}
                  <a href={data.sourceCode} target="_blank">
                    SourceCode{" "}
                  </a>
                  {data.liveView != "" ? (
                    <span>
                      ||
                      <a href={data.liveView} target="_blank">
                        {" "}
                        {["MobileApp"].includes(data.type)  ? "Download" : "LiveView"}
                      </a>
                    </span>
                  ) : null}{" "}
                </>
              ) : (
                <>
                  I have contribution/worked on this projects. But due to my
                  company/organization rules I can't share Source code or
                  Liveview or Description here. You can check my other projects.
                </>
              )}

              <br />

              <h4 style={{ marginBottom: "-2px" }}>Description</h4>
            </div>
          </BootstrapDialogTitle>

          <DialogContent dividers>
            <Typography
              gutterBottom
              style={{
                textAlign: "center",
                backgroundColor: "#48566B",
                color: "#c1c9d3",
                padding: "2%",
                borderRadius: "3px",
              }}
            >
              {description[0]?.description ? (
                description[0]?.description
              ) : (
                <div>No Description Found!</div>
              )}
            </Typography>
          </DialogContent>
        </div>
      </BootstrapDialog>
    </div>
  );
}
