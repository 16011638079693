import React from 'react';
import  './drawerToggle.styles.scss';
const drawerToggle =(props) =>(
    <div onClick={props.clicked} className='DrawerToggle'>
        <div></div>
        <div></div>
        <div></div>
    </div>
);



export default drawerToggle;