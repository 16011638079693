import React,{Component} from 'react';
import Line from './components/Line/line';
import SideBar from './components/sideBar/sideBar';
import FastPage from './pages/fastPage/fastPage';
 import Skills from './pages/skills/skills';
import Projects from './pages/projects/projects';
 import CvPart from './components/CVPart/CVpart';
import AboutMe from './pages/aboutMe/aboutMe';
import Qualification from './pages/qualification/Qualification';
import classes from './App.css';
import ToggleSideBar from './components/toggleSideBar/toggleSideBar';
import {Grid,Paper} from '@mui/material';

class App extends Component {

  

  state = {
    showToggleSideBar:false,
    start:true,
  }

  showToggleSideBarHandler =()=>{
      this.setState({showToggleSideBar : !this.state.showToggleSideBar});
  }
  startHandler =()=>{
    this.setState({start : true});
  }

  scrollToTop=()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }


  componentDidMount() {
    // console.log("componentDidMount");
    // //3128410948
    // ReactGA.initialize('UA-216199339-1');
    // ReactGA.pageview(window.location.pathname);
  }


  render() {
    let bodyData='';

    bodyData= <FastPage startClick={this.startHandler}/>

    
    if(this.state.start){
      bodyData=(
        <div>
          <div className={classes.AllInfo}>
          <Grid container  spacing={2} >
            <Grid item xs={12} >
              <FastPage start={this.state.start}/>
            </Grid>
            <Grid item xs={12} >
                  <Line/>
            </Grid>
            <Grid item xs={12} >
              <AboutMe/>
            </Grid>
{/* 
            <Grid item xs={12} >
              <Line/>
            </Grid>
            <Grid item xs={12} >
                  <Education/>
            </Grid> */}


            <Grid item xs={12} >
                  <Line/>
            </Grid>
            <Grid item xs={12} >
              <Skills/>
            </Grid>
            <Grid item xs={12} >
                  <Line/>
            </Grid>
            <Grid item xs={12} >
                <Qualification/>
            </Grid>
            <Grid item xs={12} >
                  <Line/>
            </Grid>
            <Grid item xs={12} >
                  <Projects/>
            </Grid>
           
            <Grid item xs={12} >
                <CvPart/>
            </Grid>
          </Grid>
       
        
            {/* <AboutMe/>
            <Skills/>
            <Projects/> */}
        </div>

     
       


                <Paper sx={{ display: { lg: 'none', md: 'block' } }}>
                <ToggleSideBar 
                  toggleBar={this.showToggleSideBarHandler}
                  showToggleSideBar = {this.state.showToggleSideBar}
                  />
                </Paper>
                <Paper sx={{ display: { lg: 'block', md: 'none', xs: 'none' } }}>   <SideBar/> </Paper>


         {/* <ContactMe/> */}
        </div>
      )
    }

  return (
    <div className="App">
      {bodyData}
    
      {/* <button className={classes.ScrollToTop} onClick={()=>this.scrollToTop()}>TOP</button> */}
    </div>


  )}
}

export default App;


//Bugs

/* ToggleDwawer is not hidding when its alreadt opened */