import React,{Component} from 'react';
import  './sideBar.styles.scss';
import ScrollTo from '../ScrollTo/scrollTo';
import {Hidden} from '@mui/material'



class SideBar extends Component {

  scrollToTop=()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  render() {

    return (

        <div className='sideBar'>
            {/* <div className='options'> <ScrollTo  name='Home' to='home'/></div> */}
            <div className='options'><div style={{padding:'8px'}} onClick={()=>this.scrollToTop()}>Home</div></div>
            <div className='options'><ScrollTo  name='About Me' to='AboutMe'/></div>
            <div className='options'><ScrollTo name='Skills' to='skills'/></div>
            <div className='options'><ScrollTo name='Qualification' to='qualification'/></div>
            <div className='options'><ScrollTo name='Projects' to='projects'/></div>
            <div className='options'><ScrollTo name='CV' to='CV'/></div>
        </div>

)}
}

export default SideBar;
